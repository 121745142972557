import { Directive, NgZone, OnDestroy, OnInit } from '@angular/core';

import { ScrollingService } from '../services/scrolling.service';

@Directive({
  selector: '[accountScrollingElement]',
  standalone: true,
})
export class WindowScrollDirective implements OnInit, OnDestroy {
  private eventOptions: boolean | { capture?: boolean; passive?: boolean };

  private timer: null | string | number | NodeJS.Timeout;

  constructor(
    private readonly ngZone: NgZone,
    private readonly scrollingService: ScrollingService
  ) {
    (window as any)['angularRef'] = {
      zone: this.ngZone,
      scrollDirective: this,
    };
  }

  ngOnInit(): void {
    if (this.passiveSupported()) {
      this.eventOptions = {
        capture: true,
        passive: true,
      };
    } else {
      this.eventOptions = true;
    }
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.callScrollFromOutside, <any>this.eventOptions);
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.callScrollFromOutside, <any>this.eventOptions);
  }

  passiveSupported(): boolean {
    let passiveSupported = false;
    try {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const options = Object.defineProperty({}, 'passive', {
        get: () => {
          passiveSupported = true;
          return true;
        },
      });
    } catch {
      passiveSupported = false;
    }

    return passiveSupported;
  }

  scroll(): void {
    this.scrollingService.setScrolling(true);
    if (this.timer !== 'undefined' && this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.scrollingService.setScrolling(false);
    }, 50);
  }

  private readonly callScrollFromOutside = (): void => {
    (window as any)['angularRef'].zone.run(() => {
      (window as any)['angularRef'].scrollDirective.scroll();
    });
  };
}
